import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants'; 

const FindUs = () => (
  <div className='app__bg app__wrapper section__padding' id='contact'>
    <div className='app__wrapper_info'>
      <SubHeading title="contact" id='contact'/>
      <h1 className='headtext__cormorant' style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className='app__wrapper-content'>
      <p className='p__opensans'>Lane Ends Bungalow, Whatcroft Hall Lane, Rudheath, CW9 75G</p>
      <p className='p__cormorant' style={{color: '#DCCA87', margin: '2rem 0'}}>Opening Hours</p>
      <p className='p__opensans'>Monday-Friday: 08:00 Am - 12:00 Am</p>
      <p className='p__opensans'>Saturday-Sunday: 07:00 Am - 11:00 Pm</p>
      </div>

      <button className='custom__button' style={{ marginTop: '2rem' }}>Visit Us</button>

    </div>

    <div className='app__wrapper_img'>
    <img src={images.findus} alt="findus" />
    </div>
  </div>
);

export default FindUs;
